@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;400;500;700&display=swap);
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: 'Inconsolata', monospace;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* Joshua Samaniego Updates */
html {
  width: 100vw;
  height: 100vh;
}
body {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow: auto;
}


.WalletConnect {
  min-width: 100px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  width: -moz-fit-content;
  height: 38px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  border: 2px solid white;
  -ms-user-select: none;
      user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
}
.WalletConnectLight {
  min-width: 100px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  width: -moz-fit-content;
  height: 38px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  border: 2px solid #262626;
  -ms-user-select: none;
      user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
}
.WalletConnected {
  display: flex;
  justify-content: center;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
  font-weight: 300;
}
.Jazzicon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

/* Media Queries */
@media (max-width: 400px) {
  .WalletConnect {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: -30px;
    padding: 10px;
  }
  .WalletConnectLight {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: -30px;
    padding: 10px;
  }
  .WalletConnected {
    flex-direction: column;
    width: 150px;
    text-align: center;
  }
  .WalletConnected > p {
    margin-top: 3px;
    margin-bottom: -2px;
  }
  .Jazzicon {
    margin-right: 0px;
    margin-top: -5px;
  }
}

.Nav {
  width: 92%;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.TellorLogo {
  width: 140px;
}

/* Media Queries */
@media (max-width: 600px) {
  .Nav {
    flex-direction: column;
    justify-content: space-around;
    padding: 20px 0 54px;
  }
}
@media (max-width: 500px) {
  .Nav {
    justify-content: space-around;
  }
}

.Footer {
  width: 100%;
  height: 77px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1c1c1c;
}
.ModeSwitcher {
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  margin-right: 28px;
}
.ModeSwitcherText {
  color: #777777;
}
.FooterText {
  color: #777777;
  margin-left: 28px;
}
/* Slider Overrides */
.css-17kdmr0-MuiButtonBase-root-MuiSwitch-switchBase {
  color: #20f092 !important;
}

/* Media Queries */
@media (max-width: 600px) {
  .Footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .FooterText {
    margin-left: 0;
  }
}

.Hero {
  width: 100%;
  height: 100%;
  color: #fafafa;
}
.HeroLight {
  width: 100%;
  height: 100%;
  color: #262626;
}
.Hero__View {
  width: 75vw;
  margin: 0 auto;
}
.Hero__View h1 {
  font-size: 60px;
  align-content: center;
}
.Hero__View h2 {
  font-size: 28px;
  margin: 10px 0;
}
.Hero__View h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  margin: 10px 0;
  margin-bottom: 30px;
}
.Hero__View h3 > a {
  color: #20f092;
}
.Hero__CTAContainer {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Hero__MainTable {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
.ConnectContainer {
  height: 40vh;
}
.ConnectButton {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: 2px solid #ffffff;
  background: transparent;
  margin: 20px 0px;
  padding: 8px 12px;
  font-family: 'Inconsolata', monospace;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
}
.ConnectButtonLight {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: 2px solid #262626;
  background: transparent;
  margin: 20px 0px;
  padding: 8px 12px;
  font-family: 'Inconsolata', monospace;
  color: #262626;
  cursor: pointer;
  font-size: 16px;
}

/* MEDIA QUERIES */
@media (max-width: 850px) {
  .Hero__CTAContainer {
    flex-direction: column;
  }
  .Hero__VoteInFavor {
    margin-bottom: 10%;
  }
  .Hero__View {
    padding: 50px 120px 80px 120px;
  }
}

@media (max-width: 800px) {
  .Hero__View {
    padding-top: 30px;
  }
  .Hero__View h1 {
    text-align: center;
    font-size: 48px;
  }
  .Hero__View h2 {
    text-align: center;
  }
  .Hero__View h3 {
    text-align: center;
  }
}

@media (max-width: 525px) {
  .Hero__View {
    padding: 30px 80px 80px 80px;
  }
}

@media (max-width: 420px) {
  .Hero__View {
    padding: 30px 50px 80px 50px;
  }
}

@media (max-width: 350px) {
  .Hero__View {
    padding: 30px 20px 80px 20px;
  }
}

.ErrModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: rgba(0, 0, 0, 0.4);
}
.ErrModal__Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  scrollbar-width: 0;
  background: #fafafa;
  color: #262626;
  padding: 2% 4% 4% 4%;
  border-radius: 10px;
  width: 50%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.ErrModal__Exit {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ErrModal__ExitIcon {
  width: 30px;
  height: 30px;
}
.ErrModal__ExitIcon:hover {
  cursor: pointer;
}
.ErrModal__Message {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ErrModal__Message h1 {
  font-size: 1.25rem;
  margin-bottom: 5%;
}
.ErrModal__Message p {
  font-size: 1.1rem;
  font-weight: 300;
  text-align: center;
  line-height: 1.5rem;
}

.TxnLoader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: rgba(0, 0, 0, 0.4);
}
.TxnLoader h1 {
  font-size: 1.5rem;
  color: #fafafa;
}

.TxnLoader__Icon {
  margin-top: 20px;
  max-width: 15rem;
  width: 100%;
  height: auto;
  stroke-linecap: round;
}
.TxnLoader__Icon > circle {
  fill: none;
  stroke-width: 3.5;
  -webkit-animation-name: preloader;
  animation-name: preloader;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-transform-origin: 170px 170px;
  transform-origin: 170px 170px;
  will-change: transform;
}
.TxnLoader__Icon > circle:nth-of-type(1) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
  stroke-dasharray: 550;
}
.TxnLoader__Icon > circle:nth-of-type(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
  stroke-dasharray: 500;
}
.TxnLoader__Icon > circle:nth-of-type(3) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
  stroke-dasharray: 450;
}
.TxnLoader__Icon > circle:nth-of-type(4) {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
  stroke-dasharray: 300;
}

@-webkit-keyframes preloader {
  50% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes preloader {
  50% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.TxnModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: rgba(0, 0, 0, 0.4);
}
.TxnModal__Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  scrollbar-width: 0;
  background: #fafafa;
  padding: 2% 4% 4% 4%;
  border-radius: 10px;
  width: 50%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.TxnModal__ContentLight {
  display: flex;
  flex-direction: column;
  align-items: center;
  scrollbar-width: 0;
  background: #fafafa;
  color: #262626;
  padding: 2% 4% 4% 4%;
  border-radius: 10px;
  width: 50%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.TxnModal__Exit {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.TxnModal__ExitIcon {
  width: 30px;
  height: 30px;
}
.TxnModal__ExitIcon:hover {
  cursor: pointer;
}
.TxnModal__Message {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.TxnModal__Message h1 {
  font-size: 1.25rem;
  margin-bottom: 5%;
}
.TxnModal__Message p {
  font-size: 1.1rem;
  font-weight: 300;
  text-align: center;
  line-height: 1.5rem;
}
.TxnModal__Message a {
  margin-top: 2%;
  width: 350px;
  word-wrap: break-word;
  text-align: center;
  color: #555555;
  overflow: scroll;
}

.BuyModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: rgba(0, 0, 0, 0.4);
}
.BuyModal__Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  scrollbar-width: 0;
  background: #fafafa;
  padding: 2% 4% 4% 4%;
  border-radius: 10px;
  color: #262626;
  width: 50%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.BuyModal__Exit {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.BuyModal__ExitIcon {
  width: 30px;
  height: 30px;
}
.BuyModal__ExitIcon:hover {
  cursor: pointer;
}
.BuyModal__Message {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.BuyModal__Message h1 {
  font-size: 1.25rem;
  margin: 5% 0 2% 0;
}
.BuyModal__Message h2 {
  font-size: 1rem;
  margin-bottom: 5%;
}
.BuyModal__Message input {
  width: 30%;
  height: 20px;
  border: 1px solid #2dfc9f;
  border-radius: 25px;
  padding: 3px 10px;
}
.BuyModal__Message input:focus {
  outline-color: #2dfc9f;
}
.BuyModal__Message h5 {
  color: red;
  margin: 1% 0 5% 0;
}

.AllTables__Container {
  width: 75vw;
  margin-bottom: 50px;
  transition: 0.3s;
}
.AllTables__Container h2 {
  text-align: center;
  margin: 20px 0;
  font-size: 32px;
}
table {
  width: 100%;
  height: auto;
  transition: 0.3s;
}

/*   ISSUED TREASURIES   */
.IssuedTreasuries__Header > tr {
  width: 100%;
  display: flex;
  text-align: center;
  padding-bottom: 3px;
  border-bottom: 2px solid #555555;
  flex: 1 1;
}
.IssuedTreasuries__Header > tr > th:nth-of-type(1) {
  flex: 0.2 1;
}
.IssuedTreasuries__Header > tr > th:nth-of-type(2) {
  flex: 0.1 1;
}
.IssuedTreasuries__Header > tr > th:nth-of-type(3) {
  flex: 0.1 1;
}
.IssuedTreasuries__Header > tr > th:nth-of-type(4) {
  flex: 0.1 1;
}
.IssuedTreasuries__Header > tr > th:nth-of-type(5) {
  flex: 0.25 1;
}
.IssuedTreasuries__Header > tr > th:nth-of-type(6) {
  flex: 0.05 1;
}
.IssuedTreasuries__Header > tr > th:nth-of-type(7) {
  flex: 0.2 1;
}
/* Body */
.IssuedTreasuries__Body > tr {
  width: 100%;
  display: flex;
  text-align: center;
  padding: 10px 0;
  border-bottom: 1px solid #555555;
  flex: 1 1;
}
.IssuedTreasuries__Body > tr > td:nth-of-type(1) {
  flex: 0.2 1;
  justify-self: center;
  align-self: center;
}
.IssuedTreasuries__Body > tr > td:nth-of-type(2) {
  flex: 0.1 1;
  justify-self: center;
  align-self: center;
}
.IssuedTreasuries__Body > tr > td:nth-of-type(3) {
  flex: 0.1 1;
  justify-self: center;
  align-self: center;
}
.IssuedTreasuries__Body > tr > td:nth-of-type(4) {
  flex: 0.1 1;
  justify-self: center;
  align-self: center;
}
.IssuedTreasuries__Body > tr > td:nth-of-type(5) {
  flex: 0.25 1;
  justify-self: center;
  align-self: center;
}
.IssuedTreasuries__Body > tr > td:nth-of-type(6) {
  flex: 0.05 1;
  justify-self: center;
  align-self: center;
}
.IssuedTreasuries__Body > tr > td:nth-of-type(7) {
  flex: 0.2 1;
  justify-self: center;
  align-self: center;
}

/*   BOUGHT TREASURIES   */
.BoughtTreasuries__Header > tr {
  width: 100%;
  display: flex;
  text-align: center;
  padding-bottom: 3px;
  border-bottom: 2px solid #555555;
  flex: 1 1;
}
.BoughtTreasuries__Header > tr > th:nth-of-type(1) {
  flex: 0.25 1;
}
.BoughtTreasuries__Header > tr > th:nth-of-type(2) {
  flex: 0.25 1;
}
.BoughtTreasuries__Header > tr > th:nth-of-type(3) {
  flex: 0.25 1;
}
.BoughtTreasuries__Header > tr > th:nth-of-type(4) {
  flex: 0.25 1;
}
/* Body */
.BoughtTreasuries__Body > tr {
  width: 100%;
  display: flex;
  text-align: center;
  padding: 10px 0;
  border-bottom: 1px solid #555555;
  flex: 1 1;
}
.BoughtTreasuries__Body > tr > td:nth-of-type(1) {
  flex: 0.25 1;
  justify-self: center;
  align-self: center;
}
.BoughtTreasuries__Body > tr > td:nth-of-type(2) {
  flex: 0.25 1;
  justify-self: center;
  align-self: center;
}
.BoughtTreasuries__Body > tr > td:nth-of-type(3) {
  flex: 0.25 1;
  justify-self: center;
  align-self: center;
}
.BoughtTreasuries__Body > tr > td:nth-of-type(4) {
  flex: 0.25 1;
  justify-self: center;
  align-self: center;
}

/*   PAID TREASURIES   */
.PaidTreasuries__Header > tr {
  width: 100%;
  display: flex;
  text-align: center;
  padding-bottom: 3px;
  border-bottom: 2px solid #555555;
  flex: 1 1;
}
.PaidTreasuries__Header > tr > th:nth-of-type(1) {
  flex: 0.25 1;
}
.PaidTreasuries__Header > tr > th:nth-of-type(2) {
  flex: 0.25 1;
}
.PaidTreasuries__Header > tr > th:nth-of-type(3) {
  flex: 0.25 1;
}
.PaidTreasuries__Header > tr > th:nth-of-type(4) {
  flex: 0.25 1;
}
/* Body */
.PaidTreasuries__Body > tr {
  width: 100%;
  display: flex;
  text-align: center;
  padding: 10px 0;
  border-bottom: 1px solid #555555;
  flex: 1 1;
}
.PaidTreasuries__Body > tr > td:nth-of-type(1) {
  flex: 0.25 1;
  justify-self: center;
  align-self: center;
}
.PaidTreasuries__Body > tr > td:nth-of-type(2) {
  flex: 0.25 1;
  justify-self: center;
  align-self: center;
}
.PaidTreasuries__Body > tr > td:nth-of-type(3) {
  flex: 0.25 1;
  justify-self: center;
  align-self: center;
}
.PaidTreasuries__Body > tr > td:nth-of-type(4) {
  flex: 0.25 1;
  justify-self: center;
  align-self: center;
}

.Global__Button {
  font-family: 'Inconsolata';
  background-color: #2dfc9f;
  border: 2px solid #2dfc9f;
  border-radius: 25px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
  padding: 10px 18px;
  color: #555555;
}
.Global__ButtonLight {
  font-family: 'Inconsolata';
  background-color: #2dfc9f;
  border: 2px solid #2dfc9f;
  border-radius: 25px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
  padding: 10px 18px;
  color: #555555;
}
.Global__Button:hover {
  cursor: pointer;
  background-color: transparent;
  color: #fafafa;
}
.Global__ButtonLight:hover {
  cursor: pointer;
  background-color: transparent;
}
.Global__Button:disabled {
  opacity: 0.4;
}
.Global__ButtonLight:disabled {
  opacity: 0.4;
}
.Global__Button:disabled:hover {
  cursor: not-allowed;
  background-color: #2dfc9f;
}
.Global__Button:disabled:hover {
  cursor: not-allowed;
  background-color: #2dfc9f;
}

.App {
  width: 100%;
  max-height: 100%;
  min-height: 100vh;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #262626;
  color: #ffffff;
  font-family: 'Inconsolata', monospace;
  transition: 0.3s;
}
.HeroContainer {
  width: 92%;
  min-height: 60vh;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #262626;
  margin-bottom: 100px;
  transition: 0.3s;
}

/* LIGHT MODE STYLES */
.AppLight {
  width: 100%;
  max-height: 100%;
  min-height: 100vh;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fafafa;
  color: #262626;
  font-family: 'Inconsolata', monospace;
  transition: 0.3s;
}
.HeroContainerLight {
  width: 92%;
  min-height: 60vh;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  margin-bottom: 100px;
  transition: 0.3s;
}

