.WalletConnect {
  min-width: 100px;
  max-width: fit-content;
  width: -moz-fit-content;
  height: 38px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  border: 2px solid white;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
}
.WalletConnectLight {
  min-width: 100px;
  max-width: fit-content;
  width: -moz-fit-content;
  height: 38px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  border: 2px solid #262626;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
}
.WalletConnected {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: auto;
  font-weight: 300;
}
.Jazzicon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

/* Media Queries */
@media (max-width: 400px) {
  .WalletConnect {
    height: fit-content;
    margin-bottom: -30px;
    padding: 10px;
  }
  .WalletConnectLight {
    height: fit-content;
    margin-bottom: -30px;
    padding: 10px;
  }
  .WalletConnected {
    flex-direction: column;
    width: 150px;
    text-align: center;
  }
  .WalletConnected > p {
    margin-top: 3px;
    margin-bottom: -2px;
  }
  .Jazzicon {
    margin-right: 0px;
    margin-top: -5px;
  }
}
