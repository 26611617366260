.Hero {
  width: 100%;
  height: 100%;
  color: #fafafa;
}
.HeroLight {
  width: 100%;
  height: 100%;
  color: #262626;
}
.Hero__View {
  width: 75vw;
  margin: 0 auto;
}
.Hero__View h1 {
  font-size: 60px;
  align-content: center;
}
.Hero__View h2 {
  font-size: 28px;
  margin: 10px 0;
}
.Hero__View h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  margin: 10px 0;
  margin-bottom: 30px;
}
.Hero__View h3 > a {
  color: #20f092;
}
.Hero__CTAContainer {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Hero__MainTable {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
.ConnectContainer {
  height: 40vh;
}
.ConnectButton {
  width: fit-content;
  border: 2px solid #ffffff;
  background: transparent;
  margin: 20px 0px;
  padding: 8px 12px;
  font-family: 'Inconsolata', monospace;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
}
.ConnectButtonLight {
  width: fit-content;
  border: 2px solid #262626;
  background: transparent;
  margin: 20px 0px;
  padding: 8px 12px;
  font-family: 'Inconsolata', monospace;
  color: #262626;
  cursor: pointer;
  font-size: 16px;
}

/* MEDIA QUERIES */
@media (max-width: 850px) {
  .Hero__CTAContainer {
    flex-direction: column;
  }
  .Hero__VoteInFavor {
    margin-bottom: 10%;
  }
  .Hero__View {
    padding: 50px 120px 80px 120px;
  }
}

@media (max-width: 800px) {
  .Hero__View {
    padding-top: 30px;
  }
  .Hero__View h1 {
    text-align: center;
    font-size: 48px;
  }
  .Hero__View h2 {
    text-align: center;
  }
  .Hero__View h3 {
    text-align: center;
  }
}

@media (max-width: 525px) {
  .Hero__View {
    padding: 30px 80px 80px 80px;
  }
}

@media (max-width: 420px) {
  .Hero__View {
    padding: 30px 50px 80px 50px;
  }
}

@media (max-width: 350px) {
  .Hero__View {
    padding: 30px 20px 80px 20px;
  }
}
