.BuyModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: rgba(0, 0, 0, 0.4);
}
.BuyModal__Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  scrollbar-width: 0;
  background: #fafafa;
  padding: 2% 4% 4% 4%;
  border-radius: 10px;
  color: #262626;
  width: 50%;
  height: fit-content;
}
.BuyModal__Exit {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.BuyModal__ExitIcon {
  width: 30px;
  height: 30px;
}
.BuyModal__ExitIcon:hover {
  cursor: pointer;
}
.BuyModal__Message {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.BuyModal__Message h1 {
  font-size: 1.25rem;
  margin: 5% 0 2% 0;
}
.BuyModal__Message h2 {
  font-size: 1rem;
  margin-bottom: 5%;
}
.BuyModal__Message input {
  width: 30%;
  height: 20px;
  border: 1px solid #2dfc9f;
  border-radius: 25px;
  padding: 3px 10px;
}
.BuyModal__Message input:focus {
  outline-color: #2dfc9f;
}
.BuyModal__Message h5 {
  color: red;
  margin: 1% 0 5% 0;
}
