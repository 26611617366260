.Global__Button {
  font-family: 'Inconsolata';
  background-color: #2dfc9f;
  border: 2px solid #2dfc9f;
  border-radius: 25px;
  width: fit-content;
  height: auto;
  padding: 10px 18px;
  color: #555555;
}
.Global__ButtonLight {
  font-family: 'Inconsolata';
  background-color: #2dfc9f;
  border: 2px solid #2dfc9f;
  border-radius: 25px;
  width: fit-content;
  height: auto;
  padding: 10px 18px;
  color: #555555;
}
.Global__Button:hover {
  cursor: pointer;
  background-color: transparent;
  color: #fafafa;
}
.Global__ButtonLight:hover {
  cursor: pointer;
  background-color: transparent;
}
.Global__Button:disabled {
  opacity: 0.4;
}
.Global__ButtonLight:disabled {
  opacity: 0.4;
}
.Global__Button:disabled:hover {
  cursor: not-allowed;
  background-color: #2dfc9f;
}
.Global__Button:disabled:hover {
  cursor: not-allowed;
  background-color: #2dfc9f;
}
